import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import {AuthProvider} from './auth/AuthProvider';
import PublicLayout from './containers/PublicLayout';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const CompaniesPublicForm = React.lazy(() => import('./views/companies/CompaniesPublicForm'));
const Login = React.lazy(() => import('./views/pages/login/Login'));

const App = ()=>{

  return (
    <AuthProvider>
      <BrowserRouter>
          <React.Suspense fallback={loading}>
            
              <Switch>
                <Route exact path="/formulario-empresa/:reduced" name="Company Public Form" render={props => <CompaniesPublicForm/>} />  
                <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />  
                <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
              </Switch>
          
          </React.Suspense>
      </BrowserRouter>
    </AuthProvider>
  );

}

export default App;
