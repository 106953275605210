import React from 'react'
import {
  TheContent,
  TheFooter,
  TheHeader
} from './index'

import {useAuth} from './../auth/AuthProvider';
import { Redirect } from 'react-router';

const TheLayout = () => {

  const auth = useAuth();

  if(!auth.isSignedIn())
    return <Redirect to='/login'/>

  return (
    <div className="c-app c-default-layout">
      {/* <TheSidebar/> */}
      <div className="c-wrapper">
        <TheHeader/>
        <div className="c-body">
          <TheContent/>
        </div>
        <TheFooter/>
      </div>
    </div>
  )
}

export default TheLayout
