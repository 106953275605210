import React, {useState, useContext} from 'react';
import AuthContext from './AuthContext';
import Swal from 'sweetalert2'
import { useHistory } from "react-router-dom";
import {API_URL} from './../config';

  function useProvideAuth() {

    var sessionToken = sessionStorage.getItem("accessToken");
    const history = useHistory();
    const [token, setToken] = useState<string|null>(sessionToken);
  
    const signin = (username:string, password:string, cb: Function) => {

      Swal.fire({
        title: 'Validando datos',
        allowOutsideClick: false,
        didOpen: () => {
          
          Swal.showLoading();
  
          fetch(API_URL + "/login", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({username, password})
          })
          .then(response => response.json().then(data => ({status: response.status, body: data})))
          .then(({status, body})=>{
            Swal.close();
            if(status === 401){
              return Swal.fire({
                icon: "error",
                title: 'Error!',
                text: body.message
              });
            }
    
            if(status === 200){
    
              setToken(body.accessToken);
    
              try{
                sessionStorage.setItem("accessToken", body.accessToken);
              }catch(e){
                console.error(e);
              }
    
            }
          
          })
          .catch(()=>{
            Swal.fire({
              icon: "error",
              title: 'Error!',
              text: "Ocurrio un error, por favor verifique su conexión a internet"
            });
          });
  
        }
      })

    };
  
    const signout = (cb: Function) => {
      setToken(null);
      sessionStorage.removeItem("accessToken");
      if(history)
        history.replace("/login")
    };

    const isSignedIn = (cb: Function) => {
      
      if(token)
        return true;

      return false;

    }
  
    return {
      token,
      signin,
      signout,
      isSignedIn
    };

  }

const AuthProvider: React.FC = ({children}) => {

  const auth = useProvideAuth();
  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
 
}

function useAuth() {
  return useContext(AuthContext);
}

export {AuthProvider, useAuth};